import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import Proposal from './pages/Proposal/Proposal';
import ProposalDesc from './pages/ProposalDesc/ProposalDesc';
import ProposalOne from './pages/Proposal/ProposalOne';
import ProposalDescOne from './pages/ProposalDesc/ProposalDescOne';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes path="/">
          <Route index element={<Home />} />
          <Route
            path="proposal/VRIxrvGyg15ozxHv4s2HlrJQEXhAhbE85A4hO2EaNjY"
            element={<Proposal />}
          />
          <Route
            path="proposal/VRIxrvGyg15ozxHv4s2HlrJQEXhAhbE85A4hO2fsd2"
            element={<ProposalOne />}
          />
          <Route
            path="proposal/VRIxrvGyg15ozxHv4s2HlrJQEXhAhbE85A4hO2EaNjY/desc"
            element={<ProposalDesc />}
          />
          <Route
            path="proposal/VRIxrvGyg15ozxHv4s2HlrJQEXhAhbE85A4hO2fsd2/desc"
            element={<ProposalDescOne />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
