import React from 'react';
import './Proposal.scss';
import { Button } from '@mui/material';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const ProposalOne = () => {
  const navigate = useNavigate();
  return (
    <div className="proposal-page">
      <div className="proposal-container">
        <motion.div
          className="text-wrapper"
          animate={{ opacity: [0, 1] }}
          transition={{ duration: 1.5 }}
        >
          <h1>Mena Mahany</h1>
          <p className="title">
            {' '}
            Admin panel Development Proposal for{' '}
            <span style={{ color: '#55E6A5', fontWeight: '800' }}>Darby</span>
          </p>
          <span className="subHead">Written by Mena Mahany For Darby</span>
          <Button className="read-proposal" onClick={() => navigate('desc')}>
            Start Reading Proposal
          </Button>
        </motion.div>
      </div>
    </div>
  );
};

export default ProposalOne;
