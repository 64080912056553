export const services = [
  {
    id: 1,
    name: 'Web Development',
    img: 'Images/service1.jpg',
  },
  {
    id: 2,
    name: 'Mobile Application',
    img: 'Images/service2.jpg',
  },
  {
    id: 3,
    name: 'Team Agumentation',
    img: 'Images/service3.jpg',
  },
  {
    id: 4,
    name: 'Design & Branding',
    img: 'Images/service4.jpg',
  },
  {
    id: 5,
    name: 'Website & Database Hosting/Managing',
    img: 'Images/service5.jpg',
  },
  {
    id: 6,
    name: 'Full-Stack Development',
    img: 'Images/service6.jpg',
  },
  {
    id: 7,
    name: 'Network & Infrastructure Services',
    img: 'Images/service7.jpg',
  },
  {
    id: 8,
    name: 'Software Development & Integration',
    img: 'Images/service8.jpg',
  },
];
