import React, { useEffect, useState } from 'react';
import './Home.scss';
import Navbar from '../../components/Navbar/Navbar';
import { motion } from 'framer-motion';
import { CgShapeHalfCircle } from 'react-icons/cg';
import arrow from '../../assets/title-arrow.png';
import hero from '../../assets/hero-two.jpg';
import { Avatar, Button } from '@mui/material';
import av1 from '../../assets/img1.webp';
import av2 from '../../assets/img2.jpg';
import av3 from '../../assets/img3.jpg';
import av4 from '../../assets/img4.jpeg';
import { IoAdd, IoCheckmarkCircleOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { MdArrowOutward, MdOutlineDesignServices } from 'react-icons/md';
import happyMan from '../../assets/happy.png';
import star from '../../assets/star-vector.png';
import { boxLang } from './progLang';
import { IoIosArrowRoundForward } from 'react-icons/io';
import CountUp from 'react-countup';
import aboutImg from '../../assets/about-image-shape.png';
import {
  FaArrowUpRightDots,
  FaChevronRight,
  FaConnectdevelop,
  FaFacebookF,
  FaInstagram,
  FaLaptopCode,
  FaLinkedinIn,
  FaReact,
  FaRegComment,
  FaStarOfLife,
  FaTwitter,
} from 'react-icons/fa6';
import { services } from './services';
import { TbDevicesCode } from 'react-icons/tb';
import { AiFillGolden, AiOutlineTeam } from 'react-icons/ai';
import { SiJamstack } from 'react-icons/si';
import { GrAchievement } from 'react-icons/gr';
import Swipper from '../../components/Swipper/Swipper';
import { catalog } from './catalog';
import catalogImg from '../../assets/project-timeline-two1.jpg';
import { teamMember } from './team';
import whyImg from '../../assets/why-choose-right.png';
import textImg from '../../assets/Group 1.svg';
import { GiSilverBullet } from 'react-icons/gi';
import { swipperOne, swipperTwo } from './swipperData';
import testmonialimg from '../../assets/testimonials-four.jpg';
import TestmonialSwipper from '../../components/TestmonialSwipper/TestmonialSwipper';
import blogImg from '../../assets/blog1.jpg';
import blogImg2 from '../../assets/blog2.jpg';
import blogImg3 from '../../assets/blog3.jpg';
import { SlCalender } from 'react-icons/sl';
import Footer from '../../components/Footer/Footer';
import SideBar from '../../components/sideBar/SideBar';
import MiniPortfolioSec from '../../components/MiniPortfolioSection/MiniPortfolioSec';

const Home = () => {
  const [showCounter, setshowCounter] = useState(false);
  const [navFixed, setNavFixed] = useState(false);
  const [showSideBar, setshowSideBar] = useState(false);

  const scrollToDiv = () => {
    const element = document.getElementById('work-gallery-sec');

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleshowCounter = () => {
    setshowCounter(true);
    return { y: [100, 0] };
  };

  const getIcon = (serviceName) => {
    switch (serviceName) {
      case 'Web Development':
        return <FaConnectdevelop />;
      case 'Mobile Application':
        return <TbDevicesCode />;
      case 'Team Agumentation':
        return <AiOutlineTeam />;
      case 'Design & Branding':
        return <MdOutlineDesignServices />;
      case 'Website & Database Hosting/Managing':
        return <FaReact />;
      case 'Full-Stack Development':
        return <FaLaptopCode />;
      case 'Network & Infrastructure Services':
        return <SiJamstack />;
      case 'Software Development & Integration':
        return <GrAchievement />;
      default:
        return <FaConnectdevelop />;
    }
  };

  useEffect(() => {
    window.onscroll = () => {
      if (window.pageYOffset === 0) {
        setNavFixed(false);
      } else {
        if (window.pageYOffset > 125) {
          setNavFixed(true);
        }
      }
    };
  }, []);

  return (
    <div className="Home">
      <Navbar navFixed={navFixed} setshowSideBar={setshowSideBar} />

      <SideBar setshowSideBar={setshowSideBar} showSideBar={showSideBar} />
      <MiniPortfolioSec
        scrollToDiv={scrollToDiv}
        setshowSideBar={setshowSideBar}
      />
      <div className="top-header-sec">
        <div className="wrapper">
          <div className="top-heading">
            <div className="top-text">
              <span> I'm Mena Mahany</span>
              <img src={arrow} alt="arrow" className="arrow-img" />
              <motion.span
                className="anim-circle"
                animate={{ rotate: 360 }}
                transition={{
                  ease: 'linear',
                  duration: 8,
                  repeat: Infinity,
                }}
              >
                <span className="icon-wrap">
                  <CgShapeHalfCircle />
                </span>
              </motion.span>
            </div>

            <p className="bottom-text">Full Stack Web Developer</p>
          </div>
        </div>
      </div>
      <div className="explore-sec">
        <div className="wrapper">
          <motion.div
            className="left-div"
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
          >
            <p className="para">
              A motivated full Stack web developer MERN stack with more than 5+
              years of core experience in developing Full-stack web applications
              with 15+ developed projects and Top rated on Upwork
            </p>
            <img src={arrow} alt="arrow" className="arrow-img" />
            <div className="bottom-avatar-div">
              <div className="avatar-wrapper">
                <Avatar alt="Remy Sharp" src={av1} />
                <Avatar alt="Remy Sharp" src={av2} />
                <Avatar alt="Remy Sharp" src={av3} />
                <Avatar alt="Remy Sharp" src={av4} />
                <div className="add-icon">
                  <IoAdd />
                </div>
              </div>
              <p>100+ Popular Clients</p>
            </div>
          </motion.div>
          <motion.div
            className="bottom-div"
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <img src={hero} alt="hero-img" />
          </motion.div>

          <motion.div
            className="right-div"
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
          >
            <Link
              to="#"
              onClick={() => setshowSideBar(true)}
              className="explore-bttn"
            >
              <MdArrowOutward className="icon" style={{ width: 'auto' }} />
              <p>Send us Message</p>
            </Link>
          </motion.div>
        </div>
      </div>
      <motion.div
        className="project-com-sec"
        whileInView={{ opacity: [0, 1], y: [100, 0] }}
        transition={{ duration: 1 }}
      >
        <div className="wrapper">
          <div className="left">
            <img src={happyMan} alt="happy-man" />
            <div className="text-wrapper">
              <span className="project-num">100% </span>
              <p className="desc"> Customer Satisfaction</p>
              <div className="line"></div>
              <p className="bott-text">
                Customer satisfaction is our #1 priority. We're dedicated to
                delivering solutions that exceed expectations.
              </p>
            </div>
          </div>
          <div className="right">
            <img src={star} alt="star-img" className="star-abs" />
            <p className="title">Innovative Cutting Edge Solutions</p>
            <div className="bottom-avatar-div">
              <div className="avatar-wrapper">
                <Avatar alt="Remy Sharp" src={av1} />
                <Avatar alt="Remy Sharp" src={av2} />
                <Avatar alt="Remy Sharp" src={av3} />
                <Avatar alt="Remy Sharp" src={av4} />
                <div className="add-icon">
                  <IoAdd />
                </div>
              </div>
              <p>
                Embrace the future with our cutting-edge solutions that redefine
                innovation. We craft tech-forward strategies, providing your
                business with the competitive edge it deserves.
              </p>
            </div>
          </div>
        </div>
      </motion.div>
      <div className="prog-lang-sec">
        <div className="wrapper">
          <motion.h1
            className="sec-title"
            whileInView={{ opacity: [0, 1] }}
            transition={{ duration: 5 }}
          >
            Here Are Numerous Topics That Will Enhance Your Skills to Employed
            Technolgies
          </motion.h1>
          <motion.div
            className="boxes-wrapper"
            whileInView={{ opacity: [0, 1], y: [100, 0] }}
            transition={{ duration: 1 }}
          >
            {boxLang.map((item) => (
              <div className="box" key={item.id}>
                <img src={item.img} alt="prog-img" /> <p>{item.name}</p>
              </div>
            ))}
          </motion.div>
        </div>
      </div>
      <div className="aboutus-sec">
        <div className="wrapper">
          <motion.div
            className="left"
            whileInView={{ opacity: [0, 1], x: [-100, 0] }}
            transition={{ duration: 1 }}
          >
            <div className="top">
              <span className="heading">
                <IoIosArrowRoundForward className="icon" />
                About Me
              </span>
              <p className="slog">We’re Award-Winning Web Development Agency</p>
            </div>
            <p className="bottom-txt">
              A motivated full Stack web developer MERN stack with more than 2
              years of core experience in developing Full-stack web applications
              with 15+ developed projects and Top rated on Upwork
            </p>
            <motion.div
              whileInView={handleshowCounter}
              onViewportLeave={() => setshowCounter(false)}
              transition={{ delay: 0.2, duration: 1 }}
              className="counter-div"
            >
              <div className="count-box">
                <p className="counter-text">
                  {showCounter && <CountUp start={0} end={100} duration={5} />}%
                </p>
                <p className="counter-desc">Clients Satisfactions</p>
              </div>
              <div className="count-box">
                <p className="counter-text">
                  {showCounter && <CountUp start={0} end={100} duration={5} />}%
                </p>
                <p className="counter-desc">Success Rate</p>
              </div>
              <div className="count-box">
                <p className="counter-text">
                  {showCounter && <CountUp start={0} end={15} duration={5} />}+
                </p>
                <p className="counter-desc">Projects Complete</p>
              </div>
            </motion.div>
          </motion.div>
          <motion.div
            className="right"
            whileInView={{ opacity: [0, 1], x: [100, 0] }}
            transition={{ duration: 1 }}
          >
            <img src={aboutImg} alt="about-img" />
            <div className="circle"></div>
          </motion.div>
        </div>
      </div>
      <div className="popular-serv-sec">
        <div className="wrapper">
          <motion.div
            className="header"
            whileInView={{
              opacity: [0, 1],
            }}
            transition={{ duration: 5 }}
          >
            <span className="sec-name" style={{ color: '#fff' }}>
              <IoIosArrowRoundForward className="icon" /> Popular Services
            </span>
            <p className="title">We Provide Amazing Web Design Solutions</p>
          </motion.div>
          <div className="serv-boxes-wrapper">
            {services.map((item) => (
              <motion.div
                className="ser-box"
                whileInView={{
                  opacity: [0, 1],
                  y: [item.id % 2 === 0 ? 18 : -18, 0],
                }}
                transition={{ duration: 1 }}
                key={item.id}
              >
                <div className="circle"></div>
                {/* <Link to="https://menamahany.tech/" className="exp-icon">
                  <MdArrowOutward />
                </Link> */}
                <div className="top-div">
                  <span className="ser-icon">{getIcon(item.name)}</span>
                  <p className="ser-name">{item.name}</p>
                </div>
                <div className="bottom-div">
                  <img src={item.img} alt={item.name} />
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
      <Swipper data={swipperOne} />
      <div id="work-gallery-sec">
        <div className="wrapper">
          <div className="top">
            <motion.div
              className="left-text"
              whileInView={{ opacity: [0, 1], x: [-50, 0] }}
              transition={{ duration: 1 }}
            >
              <span className="heading">
                <IoIosArrowRoundForward className="icon" />
                Recent Works Gallery
              </span>
              <p className="slog">Let’s Look Our Recent Project Gallery </p>
            </motion.div>
            <motion.div
              whileInView={{ opacity: [0, 1], x: [-50, 0] }}
              transition={{ duration: 1 }}
            >
              <Link
                to="https://www.upwork.com/freelancers/~01be178d3e5df517bb"
                target="_blank"
                className="explore-bttn"
              >
                <MdArrowOutward className="icon" />
                <p>Explore More</p>
              </Link>
            </motion.div>
          </div>
          <motion.div
            className="bottom-div"
            whileInView={{ opacity: [0, 1], y: [50, 0] }}
            transition={{ duration: 1 }}
          >
            {catalog.map((item) => (
              <Link
                className="project-box"
                key={item.id}
                to={item.link}
                target="_blank"
              >
                <span>0{item.id}</span>
                <p className="proj-name">{item.name}</p>
                <MdArrowOutward className="icon" />
                <img src={item.img} alt="proj-img" className="proj-img" />
              </Link>
            ))}
          </motion.div>
        </div>
      </div>
      {/* <div className="team-sect">
        <div className="wrapper">
          <div className="header">
            <span className="sec-name">
              <IoIosArrowRoundForward className="icon" /> Team Members
            </span>
            <motion.p
              className="title"
              whileInView={{
                opacity: [0, 1],
              }}
              transition={{ duration: 2 }}
              style={{ marginTop: '16px' }}
            >
              Meet Our Professionals Team
            </motion.p>
          </div>
          <div className="team-box-wrapper">
            {teamMember.map((memb) => (
              <motion.div
                className="box"
                whileInView={{
                  opacity: [0, 1],
                }}
                transition={{
                  delay: memb.id / 6,
                  duration: 1,
                }}
                key={memb.id}
                viewport={{ once: true }}
              >
                <div className="img">
                  <img src={memb.img} alt="member-img" />
                </div>
                <div className="text">
                  <p className="memb-name">{memb.name}</p>
                  <MdArrowOutward className="icon" />
                </div>
                <span className="role">{memb.role}</span>
                <div className="abs-div">
                  <Link to="https://menamahany.tech/" className="viewD-bttn">
                    View Details <MdArrowOutward className="icon" />
                  </Link>
                  <div className="social-div">
                    <Link to="https://menamahany.tech/" className="social-link">
                      <FaFacebookF />
                    </Link>
                    <Link to="https://menamahany.tech/" className="social-link">
                      <FaTwitter />
                    </Link>
                    <Link to="https://menamahany.tech/" className="social-link">
                      <FaInstagram />
                    </Link>
                    <Link to="https://menamahany.tech/" className="social-link">
                      <FaLinkedinIn />
                    </Link>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div> */}
      <div className="why-chooseUs-sec">
        <div className="wrapper">
          <div className="left">
            <motion.div
              className="top"
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 2 }}
            >
              <span className="sec-title">
                <IoIosArrowRoundForward className="icon" />
                Why Choose Us
              </span>
              <p className="heading">
                An Affordable Tech Consultant you can Trust
              </p>
            </motion.div>
            <motion.div
              className="bottom"
              whileInView={{ opacity: [0, 1], y: [100, 0] }}
              transition={{ duration: 1 }}
            >
              <div className="box">
                <span className="box-title">
                  <FaChevronRight className="icon" />
                  Competitive rates
                </span>
                <span className="desc-txt">
                  We deliver high-quality services at budget-friendly prices,
                  ensuring you get exceptional value without compromising on
                  excellence.
                </span>
              </div>
              <div className="box">
                <span className="box-title">
                  <FaChevronRight className="icon" />
                  Premium Development
                </span>
                <span className="desc-txt">
                  Meticulously crafted, our solutions stand as a testament to
                  field expertise and innovation
                </span>
              </div>
              <div className="box">
                <span className="box-title">
                  <FaChevronRight className="icon" />
                  Satisfaction Guaranteed
                </span>
                <span className="desc-txt">
                  We deliver only the best products to make sure that every
                  customer walks away happy
                </span>
              </div>
              <div className="box">
                <span className="box-title">
                  <FaChevronRight className="icon" />
                  Flexible Retinueand
                </span>
                <span className="desc-txt">
                  A diverse and agile workforce ready to tailor your bespoke
                  project
                </span>
              </div>
            </motion.div>
          </div>
          <motion.div
            className="right"
            whileInView={{ opacity: [0, 1], x: [-50, 0] }}
            transition={{ duration: 1 }}
          >
            <img src={whyImg} alt="why-img" className="main-img" />
            <div className="circle">
              <img src={textImg} alt="text-img" className="text-img" />
            </div>
          </motion.div>
        </div>
      </div>
      <div className="pricing-sec">
        <div className="wrapper">
          <div className="header">
            <span className="sec-name">
              <IoIosArrowRoundForward className="icon" /> Pricing Package
            </span>
            <motion.p className="title" style={{ paddingTop: '20px' }}>
              Best Pricing Package For All Web Development Solutions
            </motion.p>
          </div>
          <div className="pricing-boxes">
            <motion.div
              className="box"
              whileInView={{ opacity: [0, 1], y: [100, 0] }}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
            >
              <FaArrowUpRightDots className="icon" />
              <p className="pack-name">Regular Package</p>
              <p className="pack-price">
                $ <span className="price-span">9.99</span> /per month
                <br></br>
                (no upfront payments - pay as you go)
              </p>
              <ul className="package-list">
                <li>
                  <IoCheckmarkCircleOutline className="icon" />
                  Landing Page Design
                </li>
                <li>
                  <IoCheckmarkCircleOutline className="icon" />
                  Web Development
                </li>
                <li>
                  <IoCheckmarkCircleOutline className="icon" />
                  SEO Optimizations
                </li>
                <li>
                  <IoCheckmarkCircleOutline className="icon" />
                  Responsive Design
                </li>
                <li>
                  <IoCheckmarkCircleOutline className="icon" />
                  Quality Assurance
                </li>
                <li>
                  <IoCheckmarkCircleOutline className="icon" />
                  AWS Cloud Deployemt + Maintainence
                </li>
              </ul>
              <Button
                className="pack-link"
                onClick={() => setshowSideBar(true)}
              >
                Contact Us <MdArrowOutward className="icon-link" />
              </Button>
            </motion.div>
            <motion.div
              className="box popular"
              whileInView={{ opacity: [0, 1], y: [100, 0] }}
              transition={{ delay: 0.1, duration: 1 }}
              viewport={{ once: true }}
            >
              <div className="badge">
                <FaStarOfLife /> <FaStarOfLife />
                POPULAR PACKAGE
                <FaStarOfLife /> <FaStarOfLife />
              </div>
              <GiSilverBullet className="icon" />
              <p className="pack-name">Silver Package</p>
              <p className="pack-price">
                $ <span className="price-span">89.99</span> /per month
                <br></br>
                (no upfront payments - pay as you go)
              </p>
              <ul className="package-list">
                <li>
                  <IoCheckmarkCircleOutline className="icon" />
                  Website designs 8-12 pages
                </li>
                <li>
                  <IoCheckmarkCircleOutline className="icon" />
                  Full-Stack Web Development (Front-end + Backend)
                </li>
                <li>
                  <IoCheckmarkCircleOutline className="icon" />
                  SEO Optimizations
                </li>
                <li>
                  <IoCheckmarkCircleOutline className="icon" />
                  Responsive Design
                </li>
                <li>
                  <IoCheckmarkCircleOutline className="icon" />
                  Quality Assurance
                </li>
                <li>
                  <IoCheckmarkCircleOutline className="icon" />
                  AWS Cloud Deployemt + Maintainence
                </li>
              </ul>
              <Button
                className="pack-link"
                onClick={() => setshowSideBar(true)}
              >
                Contact Us <MdArrowOutward className="icon-link" />
              </Button>
            </motion.div>
            <motion.div
              className="box"
              whileInView={{ opacity: [0, 1], y: [100, 0] }}
              transition={{ delay: 0.2, duration: 1 }}
              viewport={{ once: true }}
            >
              <AiFillGolden className="icon" />
              <p className="pack-name">Golden Package</p>
              <p className="pack-price">
                $ <span className="price-span">249.99</span> /per month
                <br></br>
                (no upfront payments - pay as you go)
              </p>
              <ul className="package-list">
                <li>
                  <IoCheckmarkCircleOutline className="icon" />
                  Custom E-Commerce store web Designs (Figma)
                </li>
                <li>
                  <IoCheckmarkCircleOutline className="icon" />
                  FUll-Stack E-commerce Development
                </li>
                <li>
                  <IoCheckmarkCircleOutline className="icon" />
                  SEO Optimizations
                </li>
                <li>
                  <IoCheckmarkCircleOutline className="icon" />
                  Responsive Design
                </li>
                <li>
                  <IoCheckmarkCircleOutline className="icon" />
                  Quality Assurance
                </li>
                <li>
                  <IoCheckmarkCircleOutline className="icon" />
                  AWS Cloud Deployemt + Maintainence
                </li>
              </ul>
              <Button
                className="pack-link"
                onClick={() => setshowSideBar(true)}
              >
                Contact Us <MdArrowOutward className="icon-link" />
              </Button>
            </motion.div>
          </div>
        </div>
      </div>
      <Swipper data={swipperTwo} />
      <div className="testmonial-sec">
        <div className="wrapper">
          <motion.div
            whileInView={{
              opacity: [0, 1],
              x: [-50, 0],
            }}
            // viewport={{ once: true }}
            transition={{ duration: 1 }}
            className="left"
          >
            <img src={testmonialimg} alt="test-img" />
            <div className="abs-circle"></div>
          </motion.div>
          <motion.div
            className="right"
            whileInView={{
              opacity: [0, 1],
              x: [50, 0],
            }}
            // viewport={{ once: true }}
            transition={{ duration: 1 }}
          >
            <TestmonialSwipper />
          </motion.div>
        </div>
      </div>
      <div className="news-section">
        <div className="wrapper">
          <div className="header">
            <span className="sec-name">
              <IoIosArrowRoundForward className="icon" /> Blog & News
            </span>
            <motion.p
              className="title"
              whileInView={{
                opacity: [0, 1],
              }}
              transition={{ duration: 2 }}
              style={{ paddingTop: '20px' }}
            >
              {' '}
              Read Our Latest News & Blog Get Every Updates
            </motion.p>
          </div>
          <div className="boxes-wrapper">
            <motion.div
              className="box"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 3 }}
              // viewport={{ once: true }}
            >
              <div className="img-wrapper">
                <img src={blogImg} alt="blog1" />
              </div>
              <Link to="https://menamahany.tech/" className="blog-title">
                Voice Skills For Google Assistant And Amazon Alexa
              </Link>
              <div className="blog-details">
                <div className="inner-box-det">
                  <SlCalender />
                  FEBRUARY 18, 2023
                </div>
                <div className="inner-box-det">
                  <FaRegComment />
                  COMMENT (5)
                </div>
              </div>
            </motion.div>
            <motion.div
              className="box"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 3, delay: 0.2 }}
              // viewport={{ once: true }}
            >
              <div className="img-wrapper">
                <img src={blogImg2} alt="blog1" />
              </div>
              <Link to="https://menamahany.tech/" className="blog-title">
                Inclusive Design And Accessibility Stream Heydon Pickering
              </Link>
              <div className="blog-details">
                <div className="inner-box-det">
                  <SlCalender />
                  FEBRUARY 18, 2023
                </div>
                <div className="inner-box-det">
                  <FaRegComment />
                  COMMENT (5)
                </div>
              </div>
            </motion.div>{' '}
            <motion.div
              className="box"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 3, delay: 0.4 }}
              // viewport={{ once: true }}
            >
              <div className="img-wrapper">
                <img src={blogImg3} alt="blog1" />
              </div>
              <Link to="https://menamahany.tech/" className="blog-title">
                Creating Online Environments The Work Well For Older Users
              </Link>
              <div className="blog-details">
                <div className="inner-box-det">
                  <SlCalender />
                  FEBRUARY 18, 2023
                </div>
                <div className="inner-box-det">
                  <FaRegComment />
                  COMMENT (5)
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
