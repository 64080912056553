import React from 'react';
import './Swipper.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, FreeMode } from 'swiper/modules';
import 'swiper/css';
import { FaStarOfLife } from 'react-icons/fa6';

const Swipper = ({ data }) => {
  return (
    <div className="Swipper">
      <Swiper
        loop={true}
        slidesPerView={'auto'}
        spaceBetween={30}
        autoplay={{
          delay: 0,
          disableOnInteraction: true,
        }}
        freeMode={true}
        speed={3000}
        modules={[Autoplay, FreeMode]}
        className="mySwiper"
      >
        {data.map((item) => (
          <SwiperSlide key={item.id}>
            <FaStarOfLife className="icon" />
            <p>{item.title}</p>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Swipper;
