export const catalog = [
  {
    id: 1,
    name: 'Employment Retention Credit Caluclator for U.S Company  - React.js',
    link: 'https://minorityerc.com/',
    img: './Images/projects/project1.png',
  },
  {
    id: 2,
    name: 'Full Stack Development for VOIP Business collect surveys. U.K business - React.js',
    link: 'https://connectexpert.business/',
    img: './Images/projects/project2.png',
  },
  {
    id: 3,
    name: 'Front-End Development for U.S based sport fans webiste(posts, comment, likes) - React.js',
    link: '/',
    img: './Images/projects/project3.png',
  },
  {
    id: 4,
    name: 'AI Full-Stack Development (Chat GBT Cloning) Backend + Front-End + AWS deployment',
    link: 'https://aichat.menamahany.tech',
    img: './Images/projects/project4.png',
  },
];
