import React from 'react';
import './ProposalSideBar.scss';
import { Link } from 'react-router-dom';

const ProposalSideBar = ({ active }) => {
  return (
    <div className="proposalSideBar">
      <div className="top">
        <p>Mena Mahany</p>
      </div>
      <div className="bottom-nav">
        <Link
          to="?id=introduction"
          className={`link ${active === 1 ? 'active' : ''}`}
        >
          Intrduction
        </Link>
        <Link
          to="?id=project-goal"
          className={`link ${active === 2 ? 'active' : ''}`}
        >
          Project Goals
        </Link>
        <Link
          to="?id=new-webiste"
          className={`link ${active === 3 ? 'active' : ''}`}
        >
          Estimated Hours
        </Link>
        {/* <Link className="link">Case Study</Link>
        <Link className="link">Your Investment</Link>
        <Link className="link">Guarantee</Link>
        <Link className="link">Approve Proposal</Link> */}
      </div>
    </div>
  );
};

export default ProposalSideBar;
