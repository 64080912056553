import React, { useEffect, useRef, useState } from 'react';
import './ProposalDesc.scss';
import ProposalSidBar from '../../components/proposalSideBar/ProposalSideBar';
import topProimg from '../../assets/topproposal.png';
import imgSection from '../../assets/img-sec1.jpg';
import { FaFigma } from 'react-icons/fa6';
import { VscServerProcess } from 'react-icons/vsc';
import { FaReact } from 'react-icons/fa6';
import { SiTestcafe } from 'react-icons/si';
import { FaAws } from 'react-icons/fa6';
import { RiSeoLine } from 'react-icons/ri';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useInView } from 'framer-motion';
import { useNavigate, useSearchParams } from 'react-router-dom';
import imgSection2 from '../../assets/sketchImg.jpg';

const ProposalDesc = () => {
  const [option, setOption] = useState('Headless');
  const [active, setActive] = useState();
  const [search] = useSearchParams();
  const navigate = useNavigate();
  // divide them into components to imporve the code not to be so long like this
  const introduction = useRef(null);
  const goals = useRef(null);
  const estimate = useRef(null);
  const isIntroductionInView = useInView(introduction);
  const isGoalsInView = useInView(goals);
  const isEstimateInView = useInView(estimate);
  const target = document.getElementById(search.get('id'));

  useEffect(() => {
    target?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, [target]);

  useEffect(() => {
    if (isIntroductionInView && !isGoalsInView && active !== 1) {
      setActive(1);
    } else if (isGoalsInView && active !== 2) {
      setActive(2);
    } else if (isEstimateInView && active !== 3) {
      setActive(3);
    }
  }, [isIntroductionInView, isGoalsInView, isEstimateInView]);

  return (
    <div className="ProposalDesc">
      <div className="proposalDesc-container">
        <div className="proposalDesc-wrapper">
          <ProposalSidBar active={active} />
          <div className="proposal-data">
            <div id="introduction" ref={introduction}>
              <div className="top-intro">
                <div className="text-wrapper">
                  <p>
                    New E-commerce.<br></br> Custom product.
                  </p>
                  <span>
                    Thank you for considering Me for your web development
                    project.
                  </span>
                </div>
                <div className="img-wrapper">
                  <img src={topProimg} alt="img" />
                </div>
              </div>
              <div className="section-container">
                <p className="bold-p">
                  Hi Mr.Jihad,
                  <br></br>
                  <br></br>
                  I'm looking forward to working with you!
                </p>

                <p>
                  I am thrilled to present a detailed proposal for the
                  development of your custom carpet e-commerce website, tailored
                  to provide a seamless custom product building experience.
                  Below is an outlined plan, incorporating the MERN Stack and
                  Headless with a focus on robust backend development using
                  Express.js.
                </p>
              </div>
              <div className="img-section">
                <img src={imgSection} alt="img-section" />
              </div>
              <div className="quote-section">
                <p>
                  "According to our research, 46.1% of people say a website’s
                  design is the top criteria for deciding if a company is
                  credible or not."
                  <span className="ref">- Stanford University -</span>
                </p>
              </div>
            </div>
            <div
              className="section-container grayed"
              id="project-goal"
              ref={goals}
            >
              <p className="bold-p">Project Goals:</p>
              <p>
                These goals collectively aim to deliver a state-of-the-art
                custom carpet platform that not only meets the client's
                expectations but also provides an enjoyable and personalized
                experience for end-users.
              </p>
              <ol>
                <li>
                  Seamless Customization Experience:
                  <br></br>
                  <span className="list-desc">
                    - Provide users with a seamless and intuitive customization
                    experience, allowing them to easily personalize their carpet
                    products according to their preferences.
                  </span>
                </li>
                <li>
                  Engaging Design Discussions:
                  <br></br>
                  <span className="list-desc">
                    - Facilitate in-depth design discussions to understand and
                    incorporate client preferences, ensuring the final product
                    aligns with their vision.
                  </span>
                </li>
                <li>
                  Collaborative Design Ideation:
                  <br></br>
                  <span className="list-desc">
                    - Utilize Figma for collaborative design ideation, enabling
                    efficient communication and visualization of design concepts
                    between the development team and the client.
                  </span>
                </li>
                <li>
                  Dynamic Color Customization:
                  <br></br>
                  <span className="list-desc">
                    - Implement dynamic color customization using image
                    manipulation libraries such as{' '}
                    <strong style={{ color: 'black' }}>get-rgba-palette</strong>{' '}
                    and{' '}
                    <strong style={{ color: 'black' }}>replace-color</strong>,
                    allowing users to choose and modify colors based on their
                    preferences.
                  </span>
                </li>
                <li>
                  Auto-generation of Product Attributes:
                  <br></br>
                  <span className="list-desc">
                    - Develop a backend system using Express.js to automatically
                    generate product attributes and variant IDs based on
                    customer customization, creating a comprehensive and
                    accurate product catalog.
                  </span>
                </li>
                <li>
                  Responsive Frontend Development:
                  <br></br>
                  <span className="list-desc">
                    - Create a dynamic and responsive user interface using
                    React, ensuring a smooth and interactive frontend experience
                    for users across various devices.
                  </span>
                </li>
                <li>
                  User Interaction Features:
                  <br></br>
                  <span className="list-desc">
                    - Implement interactive features that enable users to easily
                    interact with the platform, selecting and modifying
                    attributes such as colors, sizes, and other customizable
                    elements.
                  </span>
                </li>
                <li>
                  Robust Backend Infrastructure:
                  <br></br>
                  <span className="list-desc">
                    - Build a robust backend infrastructure using Express.js,
                    ensuring scalability, performance, and the ability to handle
                    high volumes of customization requests.
                  </span>
                </li>
                <li>
                  Rigorous Testing and Debugging:
                  <br></br>
                  <span className="list-desc">
                    - Conduct rigorous testing throughout the development
                    process to identify and resolve any potential issues,
                    ensuring a bug-free and reliable custom product building
                    experience.
                  </span>
                </li>
                <li>
                  User Acceptance Testing (UAT):
                  <br></br>
                  <span className="list-desc">
                    - Perform user acceptance testing to validate that the
                    custom product building experience meets and exceeds client
                    expectations and provides a user-friendly interface.
                  </span>
                </li>
                <li>
                  Rigorous Testing and Debugging:
                  <br></br>
                  <span className="list-desc">
                    - Conduct rigorous testing throughout the development
                    process to identify and resolve any potential issues,
                    ensuring a bug-free and reliable custom product building
                    experience.
                  </span>
                </li>
                <li>
                  AWS Deployment for Reliability:
                  <br></br>
                  <span className="list-desc">
                    - Configure and deploy the application on AWS, leveraging
                    cloud services to ensure a reliable, secure, and scalable
                    hosting environment.
                  </span>
                </li>
                <li>
                  Post-deployment Optimization:
                  <br></br>
                  <span className="list-desc">
                    - Conduct post-deployment testing and optimization to
                    guarantee smooth and efficient operation in a live
                    environment, addressing any performance or compatibility
                    issues.
                  </span>
                </li>
                <li>
                  Timely Project Delivery:
                  <br></br>
                  <span className="list-desc">
                    - Configure and deploy the application on AWS, leveraging
                    cloud services to ensure a reliable, secure, and scalable
                    hosting environment.
                  </span>
                </li>
              </ol>
            </div>
            <div id="new-webiste" ref={estimate}>
              <div className="new-website-header">
                <div className="overlay">
                  <p>Let's start with Your New Website</p>
                </div>
              </div>
              <div className="webiste-parts">
                <div className="box">
                  <FaFigma />
                  <p className="box-title">Figma Product Design</p>
                  <ul>
                    <li>
                      In-depth discussions for understanding design preferences
                      and requirements.
                    </li>
                    <li>
                      Utilization of Figma for collaborative design ideation.
                    </li>
                    <li>
                      Creation of wireframes and prototypes for a captivating
                      custom product building experience.
                    </li>
                  </ul>
                  <span className="estimated">Estimated Hours: (30 - 40h)</span>
                  <Button
                    onClick={() => navigate('?id=cost')}
                    className="hours-bttn"
                  >
                    Show Budget
                  </Button>
                </div>
                <div className="box">
                  <VscServerProcess />
                  <p className="box-title">
                    Backend Development <br></br>with Express.js
                  </p>
                  <ul>
                    <li>
                      Implementation of Express.js for a powerful and scalable
                      backend.
                    </li>
                    <li>
                      Integration of image manipulation libraries ({' '}
                      <strong style={{ color: 'black' }}>
                        get-rgba-palette
                      </strong>{' '}
                      and{' '}
                      <strong style={{ color: 'black' }}>replace-color</strong>,
                      ) for dynamic color customization.
                    </li>
                    <li>
                      Auto-generation of product attributes based on customer
                      preferences.
                    </li>
                  </ul>
                  <div style={{ width: '80%', maxWidth: '200px' }}>
                    <FormControl fullWidth>
                      <InputLabel>Development option</InputLabel>
                      <Select
                        value={option}
                        label="Development option"
                        onChange={(e) => setOption(e.target.value)}
                      >
                        <MenuItem value={'Headless'}>Headless</MenuItem>
                        <MenuItem value={'Custom'}>Custom Solution</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <span className="estimated">
                    Estimated Hours for Headless approach:{' '}
                    {option === 'Custom' ? '220 - 240h ' : '90 - 120h'}
                  </span>
                  <Button
                    onClick={() => navigate('?id=cost')}
                    className="hours-bttn"
                  >
                    Show Budget
                  </Button>
                </div>
                <div className="box">
                  <FaReact />
                  <p className="box-title">Frontend Development (React)</p>

                  <ul>
                    <li>
                      Creation of a dynamic and intuitive user interface using
                      React.
                    </li>
                    <li>
                      Seamless integration with the Express.js backend for a
                      responsive user experience.
                    </li>
                    <li>
                      Implementation of interactive features for users to
                      customize their carpet products.
                    </li>
                  </ul>
                  <span className="estimated">
                    Estimated Hours for Headless approach: (90 - 140h)
                  </span>
                  <Button
                    onClick={() => navigate('?id=cost')}
                    className="hours-bttn"
                  >
                    Show Budget
                  </Button>
                </div>
                <div className="box">
                  <SiTestcafe />
                  <p className="box-title">Testing and Debugging:</p>
                  <ul>
                    <li>
                      Rigorous testing of the entire system to identify and
                      resolve potential issues.
                    </li>
                    <li>
                      User acceptance testing to ensure the custom product
                      building experience meets expectations.
                    </li>
                  </ul>
                  <span className="estimated">
                    Unit Testing for Endpoints(backend): (10 - 20h)
                  </span>
                  <Button
                    onClick={() => navigate('?id=cost')}
                    className="hours-bttn"
                  >
                    Show Budget
                  </Button>
                </div>
                <div className="box">
                  <FaAws />
                  <p className="box-title">Deployment on AWS:</p>
                  <ul>
                    <li>
                      Configuration and setup of AWS infrastructure for reliable
                      and scalable hosting.
                    </li>
                    <li>
                      Deployment of the application on AWS, leveraging cloud
                      services for optimal performance.
                    </li>
                    <li>
                      Post-deployment testing to ensure seamless operation in a
                      live environment.
                    </li>
                  </ul>
                  <span className="estimated">
                    Estimated with CI/CD: (15 - 25h)
                  </span>
                  <span className="estimated">
                    Estimated without CI/CD: (5 - 8h)
                  </span>
                  <Button
                    onClick={() => navigate('?id=cost')}
                    className="hours-bttn"
                  >
                    Show Budget
                  </Button>
                </div>
                <div className="box">
                  <RiSeoLine />
                  <p className="box-title">Seo Optimization</p>
                  <ul>
                    <li>
                      Keyword research and integration to improve search engine
                      rankings.
                    </li>
                    <li>
                      Optimization of meta tags, headers, and URLs for better
                      search engine visibility.
                    </li>
                    <li>
                      Implementation of responsive design for improved mobile
                      search rankings.
                    </li>
                    <li>
                      Regular monitoring and reporting on SEO performance for
                      continuous improvement.
                    </li>
                  </ul>
                  <span className="estimated">
                    Estimated without CI/CD: (8 - 10h)
                  </span>
                  <Button
                    onClick={() => navigate('?id=cost')}
                    className="hours-bttn"
                  >
                    Show Budget
                  </Button>
                </div>
              </div>
              <div className="img-section">
                <img src={imgSection2} alt="img-section" />
              </div>
              <div className="budget">
                <div className="choose-option">
                  <p>Choose Desired Development Option</p>
                  <span>(Estimated Budget)</span>
                  <div className="select-div">
                    <FormControl className="select-mui">
                      <InputLabel>Development option</InputLabel>
                      <Select
                        value={option}
                        label="Development option"
                        onChange={(e) => setOption(e.target.value)}
                      >
                        <MenuItem value={'Headless'}>Headless</MenuItem>
                        <MenuItem value={'Custom'}>Custom Solution</MenuItem>
                      </Select>
                    </FormControl>
                    <p className="total">
                      <strong>Total Budget</strong>:{' '}
                      {option === 'Headless' ? '$6185' : '$8735'}
                    </p>
                  </div>
                </div>
                <div className="project-aspect" id="cost">
                  <ol>
                    <li>
                      <span className="title">
                        Figma Product Design (35h x $15 = $510)
                      </span>
                      <ul>
                        <li>
                          Home Page: <strong>8-12 hours</strong>
                        </li>
                        <li>
                          Products Page: <strong>3-5 hours</strong>
                        </li>
                        <li>
                          Single Product Page (with related products and
                          WhatsApp button):<strong> 2-3 hours</strong>
                        </li>
                        <li>
                          Checkout Page: <strong>2-3 hours</strong>
                        </li>
                        <li>
                          Privacy Policy and Shipping Page:
                          <strong>2 hours</strong>
                        </li>
                        <li>
                          Cart Page:
                          <strong>1- 2 hours</strong>
                        </li>
                        <li>
                          User Account Page:
                          <strong>3 - 5 hours </strong>
                        </li>
                        <li>
                          Thank You Page
                          <strong>1-2 hours </strong>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <span className="title">
                        Backend Development with Express.js (
                        {option === 'Headless' ? '118h' : '220'} x $25 ={' '}
                        {option === 'Headless' ? '$2950' : '$5500'})
                      </span>
                      <ul>
                        <li>
                          API Design and Setup: <strong>15-20 hours</strong>
                        </li>
                        <li>
                          Database Integration: <strong>10-15 hours </strong>
                        </li>
                        <li>
                          Order Processing and Checkout Logic:
                          <strong>12-18 hours</strong>
                        </li>
                        <li>
                          Image Processing and dynamic attribute creation :
                          <strong>20-25 hours</strong>
                        </li>
                        {option !== 'Headless' && (
                          <li>
                            Complete E-commerce Restfull Api endpoints:
                            <strong>20-25 hours</strong>
                          </li>
                        )}
                        {option !== 'Headless' && (
                          <li>
                            MongoDB Database structure and setup:
                            <strong>20-25 hours</strong>
                          </li>
                        )}
                        {option !== 'Headless' && (
                          <li>
                            Testing and Debugging:
                            <strong>35-40 hours</strong>
                          </li>
                        )}
                        <li>
                          Authentication and Authorization:
                          <strong> 8-12 hours</strong>
                        </li>
                        <li>
                          User Account Management:
                          <strong>10-15 hours</strong>
                        </li>
                        <li>
                          Testing and Debugging:
                          <strong>15-20 hours</strong>
                        </li>
                        <li>
                          Documentation and Deployment:
                          <strong>
                            {option === 'Headless' ? '18-20h' : '25-30'}{' '}
                          </strong>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <span className="title">
                        Frontend Development (React) (85h x $25 = $2125)
                      </span>
                      <ul>
                        <li>
                          Home Page: <strong>12-16 hours</strong>
                        </li>
                        <li>
                          Products Page: <strong>8-12 hours</strong>
                        </li>
                        <li>
                          Single Product Page (with related products and
                          WhatsApp button):<strong> 12-20 hours</strong>
                        </li>
                        <li>
                          Checkout Page: <strong>8-12 hours</strong>
                        </li>
                        <li>
                          Privacy Policy and Shipping Page:
                          <strong>2-3 hours</strong>
                        </li>
                        <li>
                          Cart Page:
                          <strong>6-8 hours</strong>
                        </li>
                        <li>
                          User Account Page:
                          <strong>8-12 hours </strong>
                        </li>
                        <li>
                          Thank You Page
                          <strong>1-2 hours </strong>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <span className="title">
                        SEO Optimization (10h x $25 = $250)
                      </span>
                    </li>
                    <li>
                      <span className="title">
                        AWS Deployment (10h x $35 = $350)
                      </span>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProposalDesc;
