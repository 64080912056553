import React, { useEffect, useRef, useState } from 'react';
import './ProposalDesc.scss';
import ProposalSidBar from '../../components/proposalSideBar/ProposalSideBar';
import topProimg from '../../assets/topproposal.png';
import imgSection from '../../assets/img-sec1.jpg';
import { FaFigma } from 'react-icons/fa6';
import { VscServerProcess } from 'react-icons/vsc';
import { FaReact } from 'react-icons/fa6';
import { SiTestcafe } from 'react-icons/si';
import { FaAws } from 'react-icons/fa6';
import { RiSeoLine } from 'react-icons/ri';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useInView } from 'framer-motion';
import { useNavigate, useSearchParams } from 'react-router-dom';
import imgSection2 from '../../assets/sketchImg.jpg';

const ProposalDescOne = () => {
  const [option, setOption] = useState('Headless');
  const [active, setActive] = useState();
  const [search] = useSearchParams();
  const navigate = useNavigate();
  // divide them into components to imporve the code not to be so long like this
  const introduction = useRef(null);
  const goals = useRef(null);
  const estimate = useRef(null);
  const isIntroductionInView = useInView(introduction);
  const isGoalsInView = useInView(goals);
  const isEstimateInView = useInView(estimate);
  const target = document.getElementById(search.get('id'));

  useEffect(() => {
    target?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, [target]);

  useEffect(() => {
    if (isIntroductionInView && !isGoalsInView && active !== 1) {
      setActive(1);
    } else if (isGoalsInView && active !== 2) {
      setActive(2);
    } else if (isEstimateInView && active !== 3) {
      setActive(3);
    }
  }, [isIntroductionInView, isGoalsInView, isEstimateInView]);

  return (
    <div className="ProposalDesc">
      <div className="proposalDesc-container">
        <div className="proposalDesc-wrapper">
          <ProposalSidBar active={active} />
          <div className="proposal-data">
            <div id="introduction" ref={introduction}>
              <div className="top-intro">
                <div className="text-wrapper">
                  <p>Admin Dashboard Development</p>
                  <span>
                    Thank you for considering Me for your web development
                    project.
                  </span>
                </div>
                <div className="img-wrapper">
                  <img src={topProimg} alt="img" />
                </div>
              </div>
              <div className="section-container">
                <p className="bold-p">
                  Hi Mr. Mohamed,
                  <br></br>
                  <br></br>
                  I'm looking forward to working with you!
                </p>

                <p>
                  I am thrilled to present a detailed proposal for the
                  development of your Admin dashboard Milestones. Below is an
                  outlined plan, incorporating the expected updates and
                  estimated Milestone.
                </p>
              </div>
              <div className="img-section">
                <img src={imgSection} alt="img-section" />
              </div>
              <div className="quote-section">
                <p>
                  "According to our research, 46.1% of people say a website’s
                  design is the top criteria for deciding if a company is
                  credible or not."
                  <span className="ref">- Stanford University -</span>
                </p>
              </div>
            </div>
            <div
              className="section-container grayed"
              id="project-goal"
              ref={goals}
            >
              <p className="bold-p">Project Goals:</p>
              <p>
                These goals collectively aim to deliver Fully functional Admin
                panel for Darby Mobile app include any addtional pages missing
                and handling all Api requests provided from Backend developer.
              </p>
              <ol>
                <li>
                  Seamless Functional Admin panel:
                  <br></br>
                  <span className="list-desc">
                    - Provide Admin user with a seamless and fully functional
                    admin panel free of any errors.
                  </span>
                </li>
                <li>
                  Engaging Design Discussions:
                  <br></br>
                  <span className="list-desc">
                    - Facilitate in-depth design discussions to understand and
                    incorporate client preferences, ensuring the final product
                    aligns with their vision.
                  </span>
                </li>
                <li>
                  Responsive Frontend Development:
                  <br></br>
                  <span className="list-desc">
                    - Create a dynamic and responsive user interface using
                    React, ensuring a smooth and interactive frontend experience
                    for users across various devices.
                  </span>
                </li>

                <li>
                  Robust Backend Api Integration:
                  <br></br>
                  <span className="list-desc">
                    - Connect backend infrastructure built with Express.js,
                    integrated to the Front-end using API integration, handle
                    react state management, avoid unnesseraily renders to
                    prevent memory leaks.
                  </span>
                </li>
              </ol>
            </div>
            <div id="new-webiste" ref={estimate}>
              <div className="new-website-header">
                <div className="overlay">
                  <p>Let's start with Your New Website</p>
                </div>
              </div>
              <div className="webiste-parts">
                <div className="box">
                  <VscServerProcess />
                  <p className="box-title">
                    API Integration <br></br>with Express.js
                  </p>
                  <ul>
                    <li>
                      Handle Api Connection using the provided swagger
                      documentaion, implementing state managmenet and utilize
                      React hooks to handle API requests needed.
                    </li>
                  </ul>

                  <span className="estimated">Estimated Hours: (27 - 40h)</span>
                  <Button
                    onClick={() => navigate('?id=cost')}
                    className="hours-bttn"
                  >
                    Show Budget
                  </Button>
                </div>
                <div className="box">
                  <FaReact />
                  <p className="box-title">Frontend Development (React)</p>

                  <ul>
                    <li>
                      Redesinging pages and edit colors needed to match the
                      Brand colors.
                    </li>
                    <li>
                      Add all necessarily missing pages to fulfill all the api
                      request that are not included in the current admin panel.
                    </li>
                  </ul>
                  <span className="estimated">Estimated Hours: (15 - 25h)</span>
                  <Button
                    onClick={() => navigate('?id=cost')}
                    className="hours-bttn"
                  >
                    Show Budget
                  </Button>
                </div>
                <div className="box">
                  <VscServerProcess />
                  <p className="box-title">Milestone (2) backend code review</p>
                  <ul>
                    <li>
                      Provides Detailed feedback for the backend code provided.
                    </li>
                  </ul>

                  <span className="estimated">
                    Estimated Hours: under estimation
                  </span>
                  <Button
                    onClick={() => navigate('?id=cost')}
                    className="hours-bttn"
                  >
                    Show Budget
                  </Button>
                </div>
              </div>
              <div className="img-section">
                <img src={imgSection2} alt="img-section" />
              </div>
              <div className="budget">
                <div className="choose-option">
                  <p>Please Find the estimated budget below</p>
                  <span>(Estimated Budget)</span>
                  <div className="select-div">
                    <p style={{ fontSize: '28px' }}>Admin Panel Development</p>
                    <p className="total">
                      <strong>Total Budget</strong>: $795
                    </p>
                  </div>
                </div>
                <div className="project-aspect" id="cost">
                  <ol>
                    <li>
                      <span className="title">
                        API Integration with Express.js (33h x $15 = $495)
                      </span>
                      <ul>
                        <li>
                          State managment - redux toolkit and error handling:{' '}
                          <strong>6-8 hours</strong>
                        </li>
                        <li>
                          Job posts Endpoins (CRUD): <strong>2-3 hours</strong>
                        </li>
                        <li>
                          Contracts Endpoint (CRUD): <strong>2-3 hours</strong>
                        </li>
                        <li>
                          Trips Endpoints (CRUD):<strong> 2-3 hours</strong>
                        </li>
                        <li>
                          Wallet system Endpoints (CRUD):{' '}
                          <strong>2-3 hours</strong>
                        </li>
                        <li>
                          Chats endpoints and (sockets integration) applies if
                          applicable to admin :<strong> 5-8 hours</strong>
                        </li>
                        <li>
                          Sales and Reporting Endpoints include custom functions
                          if needed for serving graphs and charts:{' '}
                          <strong>8-12 hours</strong>
                        </li>
                        <li>
                          Debugging and testing: <strong>5-6 hours</strong>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <span className="title">
                        Frontend Development (React): (20h x $15 = $300)
                      </span>
                      <ul>
                        <li>
                          Job posts new pages - list view not map view:{' '}
                          <strong>3-5 hours</strong>
                        </li>
                        <li>
                          Contracts pages (1-2 pages):
                          <strong>3-5 hours </strong>
                        </li>
                        <li>
                          Trips pages (1-2 pages):
                          <strong>3-5 hours</strong>
                        </li>
                        <li>
                          wallet system pages (1-2 pages):
                          <strong>3-5hours</strong>
                        </li>
                        <li>
                          Chat pages (1-2 pages) if applicable:
                          <strong> 3-5 hours</strong>
                        </li>
                      </ul>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProposalDescOne;
