import React, { useEffect, useState } from 'react';
import './Footer.scss';
import { MdArrowOutward } from 'react-icons/md';
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [success, setIsSuccess] = useState(false);
  const [isValidEmail, setIsvalidEmail] = useState(true);

  const isValidEmailSch = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  useEffect(() => {
    const validate = () => {
      if (email) {
        if (email.match(isValidEmailSch)) {
          setDisabled(false);
          setIsvalidEmail(true);
        } else {
          setIsvalidEmail(false);
          setDisabled(true);
        }
      } else {
        setIsvalidEmail(true);
        setDisabled(true);
      }
    };
    validate();
  }, [email]);

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      await emailjs.send(
        'service_dn4ni8e',
        'template_kdopib8',
        { email },
        process.env.REACT_APP_EMAILJS_PUBLICKEY
      );
      setIsSuccess(true);
    } catch (err) {
      console.clear();
    }
  };
  return (
    <div className="footer">
      <div className="footer-wrapper">
        <div className="left-footer">
          <p className="title"> Get ready to build your project</p>
          <div className="subscribe-input">
            <input
              type="text"
              placeholder="Email Address"
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              className="button"
              onClick={submitForm}
              disabled={disabled}
              aria-label="subscription-submit"
            >
              <MdArrowOutward />
            </button>
          </div>
          {!isValidEmail && (
            <span style={{ color: '#ba000d', paddingTop: '4px' }}>
              Please enter valid email
            </span>
          )}
          {success && <div className="success-alert">Success!, Thank you</div>}

          <span className="copyright">
            Copyright @2023, <Link to="/">Mena Mahany</Link> All Rights Reserved
          </span>
        </div>
        <div className="right-footer">
          <div className="right-top">
            {/* <Link to="https://menamahany.tech/" className="explore-bttn">
              <MdArrowOutward className="icon" />
              Send Us Message
            </Link> */}
          </div>
          <div className="bottom-links">
            <div className="links-group">
              <span className="title">Get In Touch</span>
              <ul>
                <li>Gamiet Ahmed Oraby, El Obour City, Cairo, Egypt</li>
                <li>
                  <Link to="mailto:mahanymena@gmail.com">
                    mahanymena@gmail.com
                  </Link>
                </li>
                <li>
                  <Link to="tel:+20 12 7372 0505">+20 12 7372 0505</Link>
                </li>
              </ul>
            </div>
            <div className="links-group" style={{ alignItems: 'center' }}>
              <span className="title" style={{ transform: 'translateX(-7px)' }}>
                Links
              </span>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                {/* <li>
                  <Link to="https://menamahany.tech/">Contact Us</Link>
                </li> */}
                {/* <li>
                  <Link to="https://menamahany.tech/">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="https://menamahany.tech/">Careers</Link>
                </li> */}
              </ul>
            </div>
            {/* <div className="links-group">
              <ul style={{ paddingTop: '20px' }}>
                <li>
                  <Link to="https://menamahany.tech/">Setting</Link>
                </li>
                <li>
                  <Link to="https://menamahany.tech/">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="https://menamahany.tech/">Contact Us</Link>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
        <div className="mobile-link">
          <div className="links-group">
            <span className="title">Links</span>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              {/* <li>
                <Link to="https://menamahany.tech/">Contact Us</Link>
              </li>
              <li>
                <Link to="https://menamahany.tech/">Privacy Policy</Link>
              </li>
              <li>
                <Link to="https://menamahany.tech/">Careers</Link>
              </li> */}
            </ul>
          </div>
          {/* <div className="links-group">
            <ul style={{ paddingTop: '20px' }}>
              <li>
                <Link to="https://menamahany.tech/">Setting</Link>
              </li>
              <li>
                <Link to="https://menamahany.tech/">Privacy Policy</Link>
              </li>
              <li>
                <Link to="https://menamahany.tech/">Contact Us</Link>
              </li>
            </ul>
          </div> */}
        </div>
        <div className="subscribe-input-m">
          <input
            type="text"
            placeholder="Email Address"
            onChange={(e) => setEmail(e.target.value)}
          />
          <button
            className="button"
            onClick={submitForm}
            disabled={disabled}
            aria-label="subscription-submit"
          >
            <MdArrowOutward />
          </button>
        </div>
        {success && <div className="success-alert-m">Success!, Thank you</div>}
        <span className="copyright-m">
          Copyright @2023, <Link to="/">Mena Mahany</Link> All Rights Reserved
        </span>
      </div>
    </div>
  );
};

export default Footer;
