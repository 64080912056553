import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Navigation } from 'swiper/modules';
import 'swiper/css/navigation';
import { CgQuote } from 'react-icons/cg';
import { Avatar, Rating } from '@mui/material';

const TestmonialSwipper = () => {
  return (
    <Swiper
      pagination={true}
      navigation={true}
      modules={[Pagination, Navigation]}
      className="mySwiper"
    >
      <SwiperSlide>
        <div className="swipper-wrapper">
          <p>
            <CgQuote className="icon" />
            Mena is a great freelancer and I was lucky to have him help me with
            my website. I would highly recommend!
            <CgQuote className="icon bottom" />
          </p>
          <div className="reviewee-section">
            <div className="left-review">
              <Avatar
                style={{
                  width: '65px',
                  height: '65px',
                }}
                alt="Allan"
                src=""
              />
              <div className="text">
                <p className="name">Allan Pedrana</p>
                <span className="title">CEO & Founder</span>
              </div>
            </div>
            <Rating
              style={{ color: '#e2854a' }}
              name="read-only"
              value={5}
              readOnly
            />
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="swipper-wrapper">
          <p>
            <CgQuote className="icon" />
            Working with Mena is absolutely recommended, he's your right choice,
            I have many freelancers through years and Mena and his level is
            services is highly brilliant and outstanding. He will soon become
            top #1 on platform with such etxreme quality and way of services
            soon. And he highly deserves it!
            <CgQuote className="icon bottom" />
          </p>
          <div className="reviewee-section">
            <div className="left-review">
              <Avatar
                style={{
                  width: '65px',
                  height: '65px',
                }}
                alt="Marko"
                src=""
              />
              <div className="text">
                <p className="name">Marko</p>
                <span className="title">CEO & Founder</span>
              </div>
            </div>
            <Rating
              style={{ color: '#e2854a' }}
              name="read-only"
              value={5}
              readOnly
            />
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="swipper-wrapper">
          <p>
            <CgQuote className="icon" />
            great to work with
            <CgQuote className="icon bottom" />
          </p>
          <div className="reviewee-section">
            <div className="left-review">
              <Avatar
                style={{
                  width: '65px',
                  height: '65px',
                }}
                alt="Keiron"
                src=""
              />
              <div className="text">
                <p className="name">Keiron</p>
                <span className="title">CEO & Founder of Connects Experts</span>
              </div>
            </div>
            <Rating
              style={{ color: '#e2854a' }}
              name="read-only"
              value={5}
              readOnly
            />
          </div>
        </div>
      </SwiperSlide>
      {/* <SwiperSlide>
        <div className="swipper-wrapper">
          <p>
            <CgQuote className="icon" />
            On the other hand we denounce with see righteous indignation and
            dislike men who are beguiled and demoralized by the charms offset
            pleasure moments line desire that they cannot foresee pain and
            trouble that are bound ensue and equal blame belongs their duty{' '}
            <CgQuote className="icon bottom" />
          </p>
          <div className="reviewee-section">
            <div className="left-review">
              <Avatar
                style={{
                  width: '65px',
                  height: '65px',
                }}
                alt="Remy Sharp"
                src="https://mdbcdn.b-cdn.net/img/new/avatars/2.webp"
              />
              <div className="text">
                <p className="name">James N. Johnson</p>
                <span className="title">CEO & Founder</span>
              </div>
            </div>
            <Rating
              style={{ color: '#e2854a' }}
              name="read-only"
              value={5}
              readOnly
            />
          </div>
        </div>
      </SwiperSlide> */}
    </Swiper>
  );
};

export default TestmonialSwipper;
