export const swipperOne = [
  {
    id: 1,
    title: 'Web Design',
  },
  {
    id: 2,
    title: 'Product Design',
  },
  {
    id: 3,
    title: 'Web Development',
  },
  {
    id: 4,
    title: 'SEO Optimization',
  },
  {
    id: 5,
    title: 'UX/UI Strategy',
  },
  {
    id: 6,
    title: ' Graphics',
  },
];

export const swipperTwo = [
  {
    id: 1,
    title: 'Clients Say Us ',
  },
  {
    id: 2,
    title: 'Global Clients',
  },
  {
    id: 3,
    title: 'Awards Winning',
  },
  {
    id: 4,
    title: 'Clients Say Us',
  },
  {
    id: 5,
    title: 'Global Clients',
  },
  {
    id: 6,
    title: 'Awards Winning',
  },
];
