import React, { useState } from 'react';
import './Navbar.scss';
// import logo from '../../assets/fulllogo_transparent.png';
import { Link } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa6';
import { MdOutlineDashboardCustomize } from 'react-icons/md';
import { HiMenuAlt3 } from 'react-icons/hi';
import { IoMdClose } from 'react-icons/io';

const Navbar = ({ navFixed, setshowSideBar }) => {
  const [show, setShow] = useState(false);
  const [subShow, setSubShow] = useState(0);

  const handleSubShow = (no) => {
    if (subShow === no) {
      setSubShow(0);
    } else {
      setSubShow(no);
    }
  };

  return (
    <div className={`navbar ${navFixed ? 'fixed' : ''}`}>
      <div className="wrapper">
        <div className="logo-wrapper">
          <p className="logo-name">Mena Mahany</p>
        </div>
        <div className="links-wrapper">
          <Link to="/">Home</Link>
          {/* <Link to="https://menamahany.tech/">Contact Us</Link> */}
          {/* <div className="link-hover-div">
            <Link to="/" className="main-link">
              Service{' '}
              <span className="icon">
                <FaPlus />
              </span>
            </Link>
            <div className="sub-links">
              <Link to="/">Services One</Link>
              <Link to="/">Services Two</Link>
              <Link to="/">Services Three</Link>
            </div>
          </div> */}
          <div className="link-hover-div">
            <Link to="/" className="main-link">
              Find Me
              <span className="icon">
                <FaPlus />
              </span>
            </Link>
            <div className="sub-links">
              <Link to="https://www.upwork.com/freelancers/~01be178d3e5df517bb">
                Upwork
              </Link>
              <Link to="https://www.linkedin.com/in/mena-mahany-09024719a/">
                Linkedin
              </Link>
              <Link to="https://github.com/m-mahany">GitHub</Link>

              <Link to="/">Website</Link>
            </div>
          </div>
          <div className="bttns-wrapper">
            <span className="app-bttn" onClick={() => setshowSideBar(true)}>
              <MdOutlineDashboardCustomize />
            </span>
            <span className="menu-bttn" onClick={() => setShow(!show)}>
              {!show ? <HiMenuAlt3 /> : <IoMdClose />}
            </span>
          </div>
        </div>
      </div>
      <div className={`Nav-dropdown-menu ${show && 'show'}`}>
        <Link to="/">Home</Link>
        {/* <Link to="https://menamahany.tech/">Contact us</Link> */}
        {/* <div className="link-hover-div">
          <Link to="/" className="main-link" onClick={() => handleSubShow(1)}>
            Service{' '}
            <span className="icon">
              <FaPlus />
            </span>
          </Link>
          <div className={`sub-links ${subShow === 1 && 'shown'}`}>
            <Link to="/">Services One</Link>
            <Link to="/">Services Two</Link>
            <Link to="/">Services Three</Link>
          </div>
        </div> */}
        <div className="link-hover-div">
          <Link to="/" className="main-link" onClick={() => handleSubShow(2)}>
            Find Me
            <span className="icon">
              <FaPlus />
            </span>
          </Link>
          <div className={`sub-links ${subShow === 2 && 'shown'}`}>
            <Link to="https://www.upwork.com/freelancers/~01be178d3e5df517bb">
              Upwork
            </Link>
            <Link to="https://www.linkedin.com/in/mena-mahany-09024719a/">
              Linkedin
            </Link>
            <Link to="https://github.com/m-mahany">GitHub</Link>
            <Link to="https://menamahany.tech/">Website</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
