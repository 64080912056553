export const boxLang = [
  {
    id: 1,
    name: 'Bootstrap',
    img: './Images/skill1.png',
  },
  {
    id: 2,
    name: 'HTML',
    img: './Images/skill2.png',
  },
  {
    id: 3,
    name: 'CSS',
    img: './Images/skill3.png',
  },
  {
    id: 4,
    name: 'javascript',
    img: './Images/skill4.png',
  },
  {
    id: 5,
    name: 'React',
    img: './Images/skill5.png',
  },
  {
    id: 6,
    name: 'wordpress',
    img: './Images/skill6.png',
  },
  {
    id: 7,
    name: 'php',
    img: './Images/skill7.png',
  },
  {
    id: 8,
    name: 'node.js',
    img: './Images/skill8.png',
  },
  {
    id: 9,
    name: 'Sass',
    img: './Images/skill9.png',
  },
  {
    id: 10,
    name: 'Angular',
    img: './Images/skill10.png',
  },
  {
    id: 11,
    name: 'Shopify',
    img: './Images/skill11.png',
  },
  {
    id: 12,
    name: 'Elementor',
    img: './Images/skill12.png',
  },
  {
    id: 13,
    name: 'Vue.js',
    img: './Images/skill13.png',
  },
];
